/* eslint-disable react/prop-types */
/* eslint-disable react/no-this-in-sfc */
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Box, Card, Grid, Stack, Stepper, Step, StepLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { getInstrumentList } from '../../../redux/slices/trades';
import { getOperator, getRoles } from '../../../redux/slices/operator';
import { dispatch } from '../../../redux/store';
import { FormProvider, RHFTextField, RHFSwitch } from '../../../components/hook-form';
import useAuth from '../../../hooks/useAuth';
import personalDetailValidator from './UserCreation/personalDetails/validators/personalDetailValidators';
import PersonalDetailFom from './UserCreation/personalDetails/PersonalDetailForm';
import GeneralSettingsForm from './UserCreation/personalDetails/GeneralSettingsForm';
import generalSettingsValidator from './UserCreation/personalDetails/validators/generalSettingsValidator';
import TradingSettingsForm from './UserCreation/personalDetails/TradingSettingsForm';
import tradingSettingsValidators from './UserCreation/personalDetails/validators/tradingSettingsValidator';
import axiosInstance from '../../../utils/axios';

// ----------------------------------------------------------------------

ClientAddForm.propTypes = {
  userType: PropTypes.string.isRequired,
  submitForm: PropTypes.func.isRequired,
  isDuplicate: PropTypes.bool,
};

const adminSteps = ['Personal Details', 'Brokerage Config', 'Segement Config', 'Client Limit Config'];
const clientSteps = ['Personal Details', 'General Settings', 'Trade Settings'];
// const STEPS = ['Personal Details', 'Brokerage Config', 'Segment Config', 'Client Limit Config'];
const BrokerSteps = ['Personal Details', 'Brokerage Config', 'Segement Config'];

const EQUITY_SETTINGS_LIST = [
  '500',
  '1000',
  '1500',
  '2000',
  '3000',
  '4000',
  '6000',
  '8000',
  '10000',
  '15000',
  '20000',
  '25000',
  '30000',
];
const MCX_NAME_LISTS = [
  'ALUMINIUM',
  'ZINC',
  'COPPER',
  'LEAD',
  'NATURALGAS',
  'SILVERM',
  'MSILVER',
  'SILVER',
  'MGOLD',
  'GOLD',
  'GOLDM',
  'CRUDEOIL',
];

const commodityDefaultValue = {
  brokerageValue: 0,
  intradayExposure: 0,
  holdingExposure: 0,
  refundBrokerage: 0,
  perTradeLimit: 0,
  perScriptLimit: 0,
};

export default function ClientAddForm({ submitForm, userType, isEdit, currentUser, isDuplicate }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { userId } = useParams();
  const rolesList = useSelector((state) => state.operator.rolesList);
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [allowMCX, setAllowMCX] = useState(false);
  const [allowEquity, setAllowEquity] = useState(false);
  const [allowComex, setAllowComex] = useState(false);
  let STEPS;
  switch (userType) {
    case 'Admin':
      STEPS = adminSteps;
      break;
    case 'Client':
      STEPS = clientSteps;
      break;
    case 'Broker':
      STEPS = BrokerSteps;
      break;
    default:
      STEPS = [];
  }

  const step1Schema = personalDetailValidator(user.role.rolename, userType);
  const step2Schema = generalSettingsValidator(user.role.rolename, userType, user);
  const step3Schema = tradingSettingsValidators(
    user.role.rolename,
    userType,
    EQUITY_SETTINGS_LIST,
    MCX_NAME_LISTS,
    user
  );
  const step4Schema = Yup.object().shape({
    clientCreationConfig: Yup.object().shape({
      limitClientCreation: Yup.boolean().required('Limit client Creation is requried'),
      maximumClientAllowed: Yup.number().when('limitClientCreation', {
        is: true,
        then: Yup.number()
          .typeError('Maximum Clients Allowed is required')
          .required('Maximum Clients Allowed is required')
          .min(0, 'Value must be a greater than 0'),
        otherwise: Yup.number(),
      }),
    }),
  });

  const defaultValues = useMemo(() => {
    const defaultValuesObj = {
      manager: user.role.rolename === 'admin' && userType === 'Client' ? currentUser?.manager?._id || '' : '',
      username: currentUser?.username || '',
      password: currentUser?.password || '',
      email: currentUser?.email || '',
      firstname: currentUser?.firstname || '',
      lastname: currentUser?.lastname || '',
      phone: currentUser?.phone || '',
      city: currentUser?.city || '',
      state: currentUser?.state || '',
      country: currentUser?.country || '',
      isActive: currentUser?.isActive || false,
      profitLossShare: currentUser?.profitLossShare || 0,
      superAdminProfitLossShare: currentUser?.superAdminProfitLossShare || 0,
      brokerProfitLossShare: currentUser?.brokerProfitLossShare || 0,
      profitLossType: currentUser?.profitLossType || 'adminwise',
      enablePattern: currentUser?.enablePattern || false,
      isDemoAccount: currentUser?.isDemoAccount || false,
      pattern: currentUser?.pattern || '',
      tradeConfig: {
        allowPendingTrade: currentUser?.tradeConfig?.allowPendingTrade || false,
        ...(userType === 'Client'
          ? {
              closeExistingPosition: currentUser?.tradeConfig?.closeExistingPosition || false,
              allowOrderAtLtp: currentUser?.tradeConfig?.allowOrderAtLtp || false,
              allowFreshOrder: currentUser?.tradeConfig?.allowFreshOrder || false,
              allowOrderBetweenHighLow: currentUser?.tradeConfig?.allowOrderBetweenHighLow || false,
              allowOrderBothSide: currentUser?.tradeConfig?.allowOrderBothSide || false,
              autoCloseIntraday: currentUser?.tradeConfig?.autoCloseIntraday || false,
              autoCloseOvernight: currentUser?.tradeConfig?.autoCloseOvernight || false,
              autoCloseLossPercentage: currentUser?.tradeConfig?.autoCloseLossPercentage || 100,
            }
          : {}),
      },
      NSE: {
        ...(userType !== 'Client'
          ? {
              brokerageValue: currentUser?.NSE?.brokerageValue || 0,
              brokerageType: currentUser?.NSE?.brokerageType || 'percentage',
              blockedScripts: currentUser?.NSE?.blockedScripts || [],
              ...(userType === 'Broker' && user?.profitLossType === 'brokerwise'
                ? {
                    superAdminBrokerageValue: currentUser?.NSE?.superAdminBrokerageValue || 0,
                    brokerBrokerageValue: currentUser?.NSE?.brokerBrokerageValue || 0,
                  }
                : {}),
            }
          : {
              brokerageValue: currentUser?.NSE?.brokerageValue || 0,
              intradayExposure: currentUser?.NSE?.intradayExposure || 0,
              holdingExposure: currentUser?.NSE?.holdingExposure || 0,
              refundBrokerage: currentUser?.NSE?.refundBrokerage || 0,
              enabledLotBaseTrading: currentUser?.NSE?.enabledLotBaseTrading || false,
              shareConfig: {
                nifty: currentUser?.NSE?.shareConfig?.nifty ||
                  user?.NSE?.shareConfig?.nifty || { min: 0, max: 0, maxPerTrade: 0 },
                bankNifty: currentUser?.NSE?.shareConfig?.bankNifty ||
                  user?.NSE?.shareConfig?.bankNifty || { min: 0, max: 0, maxPerTrade: 0 },
                finNifty: currentUser?.NSE?.shareConfig?.finNifty ||
                  user?.NSE?.shareConfig?.finNifty || { min: 0, max: 0, maxPerTrade: 0 },
                rest: currentUser?.NSE?.shareConfig?.rest ||
                  user?.NSE?.shareConfig?.rest || { min: 0, max: 0, maxPerTrade: 0 },
              },
            }),
      },
      MCX: {
        ...(userType !== 'Client'
          ? {
              brokerageType: currentUser?.MCX?.brokerageType || 'percentage',
              brokerageValue: currentUser?.MCX?.brokerageValue || 0,
              brokerage: {
                metal: currentUser?.MCX?.brokerage?.metal || 0,
                baseMetal: currentUser?.MCX?.brokerage?.baseMetal || 0,
                miniMetal: currentUser?.MCX?.brokerage?.miniMetal || 0,
                energy: currentUser?.MCX?.brokerage?.energy || 0,
                GOLD: currentUser?.MCX?.GOLD || 0,
                SILVER: currentUser?.MCX?.SILVER || 0,
                GOLDM: currentUser?.MCX?.GOLDM || 0,
                SILVERM: currentUser?.MCX?.SILVERM || 0,
                MGOLD: currentUser?.MCX?.MGOLD || 0,
                MSILVER: currentUser?.MCX?.MSILVER || 0,
                LEAD: currentUser?.MCX?.LEAD || 0,
                ZINC: currentUser?.MCX?.ZINC || 0,
                ALUMINIUM: currentUser?.MCX?.ALUMINIUM || 0,
                COPPER: currentUser?.MCX?.COPPER || 0,
                NATURALGAS: currentUser?.MCX?.NATURALGAS || 0,
                CRUDEOIL: currentUser?.MCX?.CRUDEOIL || 0,
              },
              blockedScripts: currentUser?.MCX?.blockedScripts || [],
              ...(userType === 'Broker' && user?.profitLossType === 'brokerwise'
                ? {
                    superAdmin: {
                      brokerageValue: currentUser?.MCX?.superAdmin?.brokerageValue || 0,
                      brokerage: {
                        metal: currentUser?.MCX?.superAdmin?.brokerage?.metal || 0,
                        baseMetal: currentUser?.MCX?.superAdmin?.brokerage?.baseMetal || 0,
                        miniMetal: currentUser?.MCX?.superAdmin?.brokerage?.miniMetal || 0,
                        energy: currentUser?.MCX?.superAdmin?.brokerage?.energy || 0,
                      },
                    },
                    broker: {
                      brokerageValue: currentUser?.MCX?.broker?.brokerageValue || 0,
                      brokerage: {
                        metal: currentUser?.MCX?.broker?.brokerage?.metal || 0,
                        baseMetal: currentUser?.MCX?.broker?.brokerage?.baseMetal || 0,
                        miniMetal: currentUser?.MCX?.broker?.brokerage?.miniMetal || 0,
                        energy: currentUser?.MCX?.broker?.brokerage?.energy || 0,
                      },
                    },
                  }
                : {}),
            }
          : {
              maximumLotforSingleTrade: currentUser?.MCX?.maximumLotforSingleTrade || 0,
              maximumOpenLotPerScript: currentUser?.MCX?.maximumOpenLotPerScript || 0,
              maximumOpenLotAllowed: currentUser?.MCX?.maximumOpenLotAllowed || 0,
              metal: currentUser?.MCX?.metal || commodityDefaultValue,
              miniMetal: currentUser?.MCX?.miniMetal || commodityDefaultValue,
              baseMetal: currentUser?.MCX?.baseMetal || commodityDefaultValue,
              energy: currentUser?.MCX?.energy || commodityDefaultValue,
              GOLD: currentUser?.MCX?.GOLD || commodityDefaultValue,
              SILVER: currentUser?.MCX?.SILVER || commodityDefaultValue,
              GOLDM: currentUser?.MCX?.GOLDM || commodityDefaultValue,
              SILVERM: currentUser?.MCX?.SILVERM || commodityDefaultValue,
              MGOLD: currentUser?.MCX?.MGOLD || commodityDefaultValue,
              MSILVER: currentUser?.MCX?.MSILVER || commodityDefaultValue,
              LEAD: currentUser?.MCX?.LEAD || commodityDefaultValue,
              ZINC: currentUser?.MCX?.ZINC || commodityDefaultValue,
              ALUMINIUM: currentUser?.MCX?.ALUMINIUM || commodityDefaultValue,
              COPPER: currentUser?.MCX?.COPPER || commodityDefaultValue,
              NATURALGAS: currentUser?.MCX?.NATURALGAS || commodityDefaultValue,
              CRUDEOIL: currentUser?.MCX?.CRUDEOIL || commodityDefaultValue,
            }),
      },
      COMEX: {
        ...(userType !== 'Client'
          ? {
              brokerageType: currentUser?.COMEX?.brokerageType || 'percentage',
              brokerageValue: currentUser?.COMEX?.brokerageValue || 0,
              brokerage: {
                gold: currentUser?.COMEX?.brokerage?.gold || 0,
                silver: currentUser?.COMEX?.brokerage?.silver || 0,
                copper: currentUser?.COMEX?.brokerage?.copper || 0,
                naturalgas: currentUser?.COMEX?.brokerage?.naturalgas || 0,
                crudeoil: currentUser?.COMEX?.brokerage?.crudeoil || 0,
              },
              blockedScripts: currentUser?.COMEX?.blockedScripts || [],
            }
          : {
              maximumLotforSingleTrade: currentUser?.COMEX?.maximumLotforSingleTrade || 0,
              maximumOpenLotPerScript: currentUser?.COMEX?.maximumOpenLotPerScript || 0,
              maximumOpenLotAllowed: currentUser?.COMEX?.maximumOpenLotAllowed || 0,
              gold: currentUser?.COMEX?.gold || commodityDefaultValue,
              silver: currentUser?.COMEX?.silver || commodityDefaultValue,
              copper: currentUser?.COMEX?.copper || commodityDefaultValue,
              naturalgas: currentUser?.COMEX?.naturalgas || commodityDefaultValue,
              crudeoil: currentUser?.COMEX?.crudeoil || commodityDefaultValue,
            }),
      },
      clientCreationConfig: {
        limitClientCreation: currentUser?.clientCreationConfig?.limitClientCreation || false,
        maximumClientAllowed: currentUser?.clientCreationConfig?.maximumClientAllowed || 0,
      },
      ...(userType === 'Client' ? { balance: currentUser?.balance || 0 } : {}),
    };

    // Dynamic generation of shareConfig based on EQUITY_SETTINGS_LIST
    if (userType === 'Client') {
      EQUITY_SETTINGS_LIST.forEach((val) => {
        defaultValuesObj.NSE.shareConfig[val] = currentUser?.NSE?.shareConfig?.[val] ||
          user?.NSE?.shareConfig?.[val] || {
            min: 0,
            max: 0,
            maxPerTrade: 0,
          };
      });
    }
    if (isDuplicate) {
      defaultValuesObj.username = '';
      defaultValuesObj.firstname = '';
      defaultValuesObj.password = '';
      defaultValuesObj.lastname = '';
      defaultValuesObj.phone = '';
      defaultValuesObj.city = '';
      defaultValuesObj.state = '';
      defaultValuesObj.country = '';
    }

    return defaultValuesObj;
  }, [currentUser, userType, user, isDuplicate]);

  const combinedSchema = Yup.object().shape({
    ...(activeStep === 0 ? step1Schema.fields : {}),
    ...(activeStep === 1 ? step2Schema.fields : {}),
    ...(activeStep === 2 ? step3Schema.fields : {}),
    ...(userType === 'Admin' && activeStep === 3 ? step4Schema.fields : {}),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(combinedSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = methods;

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getOperator(user._id));
    dispatch(getInstrumentList(null, 1, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (isEdit) {
  //     const fetchUserData = async () => {
  //       try {
  //         // Make an API call to get user data using the userId available in params
  //         // const response = await axios.get(`/user/profile/${userId}`); // Replace '/user/${userId}' with your API endpoint
  //         // const { user } = response.data; // Assuming response.data contains user data
  //         // setCurrentUser(user);
  //         setAllowEquity(user.equityConfig.allowEquity || false);
  //         setAllowMCX(user.mcxConfig.allowMCX || false);
  //         // const updatedDefaultValues = { ...defaultValues, ...user };
  //         // Set default values of the form according to user data
  //         // methods.reset(user); // Reset the form with user data
  //         // methods.reset(defaultValues);
  //       } catch (error) {
  //         console.error('Error fetching user data:', error);
  //         enqueueSnackbar(error.message, { variant: 'error' });
  //         // Handle error if needed
  //       }
  //     };
  //     fetchUserData();
  //   }
  // }, [isEdit, userId]); // Dependency array includes isEdit and userId
  useEffect(() => {
    methods.reset(defaultValues);
    setAllowEquity(((user?.NSE?.allow || user?.role?.rolename === 'superAdmin') && currentUser?.NSE?.allow) || false);
    setAllowMCX(((user?.MCX?.allow || user?.role?.rolename === 'superAdmin') && currentUser?.MCX?.allow) || false);
    setAllowComex(
      ((user?.COMEX?.allow || user?.role?.rolename === 'superAdmin') && currentUser?.COMEX?.allow) || false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isEdit, isDuplicate]);

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const validateUsername = async (username) => {
    try {
      const response = await axiosInstance.get(`/user/check-username?username=${username}`);
      return response.data.isValid;
    } catch (error) {
      console.error('Error checking username:', error);
      return false;
    }
  };
  // useEffect(() => {
  //   const validateUsername = async (username) => {
  //     try {
  //       const response = await axios.get(`/user/check-username?username=${username}`);
  //       return response.data.isValid;
  //     } catch (error) {
  //       console.error('Error checking username:', error);
  //       return false;
  //     }
  //   };

  //   const handleUsernameChange = async (username) => {
  //     // Validate username only if it's not empty
  //     if (username) {
  //       const isValid = await validateUsername(username);
  //       if (!isValid) {
  //         setError('username', { type: 'required', message: 'Username already exists' });
  //       } else {
  //         clearErrors('username');
  //       }
  //     }
  //   };

  //   // Watch for changes in the username field and trigger validation
  //   const usernameFieldValue = methods.getValues('username');
  //   if (usernameFieldValue) {
  //     handleUsernameChange(usernameFieldValue);
  //   }
  // }, [methods.getValues().username]); // Trigger useEffect only when username field changes
  const findFirstErrorField = (errorObject) => {
    let firstErrorField = null;

    const traverse = (obj, path = '') => {
      Object.entries(obj).forEach(([key, value]) => {
        if (firstErrorField) return; // Short-circuit if we've already found the first error

        if (value && typeof value === 'object' && !value.ref) {
          traverse(value, `${path}${path ? '.' : ''}${key}`);
        } else if (value?.ref) {
          firstErrorField = `${path}${path ? '.' : ''}${key}`;
        }
      });
    };

    traverse(errorObject);
    return firstErrorField;
  };

  useEffect(() => {
    // Log the errors for debugging
    console.log('Errors:', errors);

    // Find the first error field's key using the utility function
    const firstErrorKey = findFirstErrorField(errors);
    console.log('First Error Key:', firstErrorKey);

    // if (firstErrorKey) {
    //   // Try to get the DOM element using the path
    //   const errorPathArray = firstErrorKey.split('.');
    //   const nameSelector = errorPathArray.map((key) => `[name*="${key}"]`).join(' ');

    //   console.log('Name Selector:', nameSelector);

    //   let errorElement = document.querySelector(nameSelector);

    //   // If the element is not directly found, use the `ref` from errors
    //   if (!errorElement) {
    //     const errorRef = errorPathArray.reduce((acc, key) => (acc ? acc[key] : null), errors);
    //     errorElement = errorRef?.ref;
    //   }

    //   // Scroll to the error element if found
    //   if (errorElement && typeof errorElement.scrollIntoView === 'function') {
    //     errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //   }
    // }
    const errorElement = document.querySelector(`[name="${firstErrorKey}"]`);
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errors]);

  const onSubmit = async (payload) => {
    try {
      if (activeStep === 0) {
        if ((isEdit && payload.username !== currentUser.username) || !isEdit) {
          const isValid = await validateUsername(payload.username);
          if (!isValid) {
            setError('username', { type: 'required', message: 'Username already exists' });
            return;
          }
          clearErrors('username');
        }
      }

      if (activeStep === STEPS.length - 1) {
        const filteredPayload = { ...payload };
        console.log('PAYLOAD', filteredPayload);
        MCX_NAME_LISTS.forEach((name) => {
          console.log('name', name, filteredPayload.MCX[`${name}`]?.perTradeLimit);
          const perTradeLimitValue = +filteredPayload.MCX[`${name}`]?.perTradeLimit || 0;
          console.log('Per Trade LImit', perTradeLimitValue);
          if (perTradeLimitValue === 0) {
            // Remove the subfield from the payload
            delete filteredPayload.MCX[`${name}`];
          }
        });
        if (isEdit) {
          if (user.role.rolename === 'admin') {
            filteredPayload.roleId = rolesList.find((role) => role.rolename === userType.toLowerCase())._id;
          }
          await submitForm({ ...filteredPayload }, isEdit, userId);
          if (userType === 'Client' && user.role.rolename === 'admin') {
            // navigate(`/admin/broker/list`);
            navigate(-1);
          } else {
            navigate(`${pathname.split('/').slice(0, -2).join('/')}/list`);
          }
        } else {
          if (user.role.rolename === 'admin') {
            filteredPayload.roleId = rolesList.find((role) => role.rolename === userType.toLowerCase())._id;
          }
          await submitForm({ ...filteredPayload });
          if (userType === 'Client' && user.role.rolename === 'admin') {
            // navigate(`${pathname.split('/').slice(0, -2).join('/')}/list`);
            navigate(-1);
          } else {
            navigate(`${pathname.slice(0, pathname.lastIndexOf('/'))}/list`);
          }
        }
        enqueueSnackbar(`${userType} ${isEdit ? 'Updated' : 'Created'} successfully`);
      } else {
        handleNextStep();
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12} md={8}>
        <Card sx={{ p: 3 }}>
          <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
            {STEPS.map((label) => (
              <Step key={label}>
                <StepLabel sx={{ textTransform: 'uppercase' }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <PersonalDetailFom
              isEdit={isEdit}
              userType={userType}
              manager={user}
              methods={methods}
              currentUser={currentUser}
            />
          )}
          {activeStep === 1 && (
            <GeneralSettingsForm isEdit={isEdit} userType={userType} manager={user} methods={methods} />
          )}
          {activeStep === 2 && (
            <TradingSettingsForm
              {...{
                isEdit,
                userType,
                manager: user,
                methods,
                currentUser,
                EQUITY_SETTINGS_LIST,
                defaultValues,
                allowEquity,
                allowComex,
                allowMCX,
                setAllowComex,
                setAllowEquity,
                setAllowMCX,
              }}
            />
          )}
          {/* {activeStep === 0 && (
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="username" label="Username *" />
              {isEdit ? (
                <RHFTextField name="password" label="Password *" type="password" disabled />
              ) : (
                <RHFTextField
                  name="password"
                  label="Password *"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              <RHFTextField name="firstname" label="First Name" />
              <RHFTextField name="lastname" label="Last Name" />
              <RHFTextField name="email" label="Email" />
              <RHFTextField name="phone" label="Phone" type="tel" maxLength={10} />
              <RHFTextField name="city" label="City" />
              <RHFTextField name="state" label="State" />
              <RHFTextField name="country" label="Country" />
            </Box>
          )} */}
          {/* {activeStep === 1 && (
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
              }}
            >
              <RHFSwitch
                name="isActive"
                label="Activate Account"
                labelPlacement="start"
                sx={{ justifyContent: 'start' }}
              />
              <RHFSwitch
                name="tradeConfig.allowPendingTrade"
                label="Allow Pending Trades"
                labelPlacement="start"
                sx={{ justifyContent: 'start' }}
              />
              <RHFTextField name="profitLossShare" label={`Your Profit/Loss Share (%)`} />
            </Box>
          )} */}
          {/* {activeStep === 2 && (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Box alignItems={'center'} display={'flex'} flexDirection={'row'} mx={2}>
                  <Typography>Allow Equity</Typography>
                  <Switch
                    name="allowEquity"
                    label="Allow Equity"
                    sx={{ justifyContent: 'start', mx: 2 }}
                    onChange={() => setAllowEquity((prev) => !prev)}
                    checked={allowEquity}
                  />
                </Box>
                <Box alignItems={'center'} display={'flex'} flexDirection={'row'} mx={2}>
                  <Typography>Allow MCX</Typography>
                  <Switch
                    name="allowMCX"
                    label="Allow MCX"
                    sx={{ justifyContent: 'start', mx: 2 }}
                    onChange={() => setAllowMCX((prev) => !prev)}
                    checked={allowMCX}
                  />
                </Box>
                <Box alignItems={'center'} display={'flex'} flexDirection={'row'} mx={2}>
                  <Typography>Allow COMEX</Typography>
                  <Switch
                    name="allowComex"
                    label="Allow COMEX"
                    sx={{ justifyContent: 'start', mx: 2 }}
                    onChange={() => setAllowComex((prev) => !prev)}
                    checked={allowComex}
                  />
                </Box>
              </Box>
              <Accordion disabled={!allowEquity} expanded={allowEquity}>
                <AccordionSummary id="equity">
                  <Typography fontWeight={'bold'}>Equity Config</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, minmax(0, 1fr))', sm: 'repeat(2, minmax(0, 1fr))', my: 2 },
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Typography sx={{ mr: 2 }}>Equity Brokerage Type</Typography>
                      <RHFToggleButtonGroup
                        name="equityConfig.equityBrokerageType"
                        label="Equity Brokerage Type"
                        color="primary"
                        defaultValue={defaultValues.equityConfig.equityBrokerageType}
                        sx={{ width: '100%' }}
                        options={[
                          { label: 'percentage', value: 'percentage' },
                          { label: 'crorebase', value: 'crorebase' },
                        ]}
                      />
                    </Box>

                    <RHFAutoComplete
                      name="equityConfig.blockedEquityScripts"
                      // options={docs.map((instruments) => instruments.name)}
                      options={docs.filter((doc) => doc.exchange?.name === 'NFO')}
                      label="Blocked Equity Script"
                      placeholder="Select Script to Block"
                      value={methods.getValues().equityConfig.blockedEquityScripts}
                    />
                    <RHFTextField name="equityConfig.equityBrokerageValue" label="Equity Brokerage Value" type="tel" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion disabled={!allowMCX} expanded={allowMCX}>
                <AccordionSummary id="mcx">
                  <Typography fontWeight={'bold'}>MCX Config</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Typography sx={{ mr: 2 }}>MCX Brokerage Type</Typography>
                      <RHFToggleButtonGroup
                        name="mcxConfig.mcxBrokerageType"
                        label="MCX Brokerage Type"
                        defaultValue={defaultValues?.mcxConfig?.mcxBrokerageType || 'percentage'}
                        color="primary"
                        sx={{ width: '100%' }}
                        options={[
                          { label: 'percentage', value: 'percentage' },
                          { label: 'lotwise', value: 'lotwise' },
                        ]}
                      />
                    </Box>
                    <RHFAutoComplete
                      name="mcxConfig.blockedMCXScripts"
                      options={docs.filter((doc) => doc.exchange.name === 'MCX')}
                      label="Blocked MCX Script"
                      placeholder="Select Script to Block"
                      value={methods.getValues().blockedMCXScripts}
                    />
                    {methods.getValues().mcxConfig.mcxBrokerageType === 'percentage' && (
                      <RHFTextField name="mcxConfig.mcxBrokerageValue" label="MCX Brokerage Value" type="tel" />
                    )}
                    {methods.getValues().mcxConfig.mcxBrokerageType === 'lotwise' && (
                      <>
                        <Box
                          sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(2, 1fr)' },
                          }}
                        >
                          <RHFTextField
                            name="mcxConfig.metalBrokerage"
                            label="Metal brokerage"
                            helperText={errors?.mcxConfig?.metalBrokerage?.message || '( Gold, Silver )'}
                            type="tel"
                          />
                          <RHFTextField
                            name="mcxConfig.miniMetalBrokerage"
                            label="Mini Metal brokerage"
                            helperText={
                              errors?.mcxConfig?.miniMetalBrokerage?.message || '( GoldM, SilverM, MGold, MSilver )'
                            }
                            type="tel"
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(2, 1fr)' },
                          }}
                        >
                          <RHFTextField
                            name="mcxConfig.baseMetalBrokerage"
                            label="Base Metal Brokerage"
                            helperText={
                              errors?.mcxConfig?.baseMetalBrokerage?.message ||
                              '( Copper, Nickel, Lead, Zinc, Aluminium )'
                            }
                            type="tel"
                          />
                          <RHFTextField
                            name="mcxConfig.energyBrokerage"
                            label="Energy Brokerage"
                            helperText={errors?.mcxConfig?.energyBrokerage?.message || '( Natural Gas, Crude Oil )'}
                            type="tel"
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion disabled={!allowComex} expanded={allowComex}>
                <AccordionSummary id="mcx">
                  <Typography fontWeight={'bold'}>COMEX Config</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                      mb: 3,
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Typography sx={{ mr: 2 }}>COMEXX Brokerage Type</Typography>
                      <RHFToggleButtonGroup
                        name="comexConfig.comexBrokerageType"
                        label="Comex Brokerage Type"
                        defaultValue={defaultValues?.comexConfig?.comexBrokerageType || 'percentage'}
                        color="primary"
                        sx={{ width: '100%' }}
                        options={[
                          { label: 'percentage', value: 'percentage' },
                          { label: 'lotwise', value: 'lotwise' },
                        ]}
                      />
                    </Box>
                    <RHFAutoComplete
                      name="comexConfig.blockedComexScripts"
                      options={docs.filter((doc) => doc.exchange.name === 'COMEX')}
                      label="Blocked comex Script"
                      placeholder="Select Script to Block"
                      value={methods.getValues().blockedComexScripts}
                    />

                    {methods.getValues().comexConfig.comexBrokerageType === 'percentage' && (
                      <RHFTextField name="comexConfig.comexBrokerageValue" label="Comex Brokerage Value" type="tel" />
                    )}
                  </Box>
                  {methods.getValues().comexConfig.comexBrokerageType === 'lotwise' && (
                    <>
                      <Box
                        sx={{
                          display: 'grid',
                          columnGap: 2,
                          rowGap: 3,
                          gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(5, 1fr)' },
                        }}
                      >
                        <RHFTextField name="comexConfig.goldBrokerage" label="GOLD brokerage" type="tel" />
                        <RHFTextField name="comexConfig.silverBrokerage" label="Silver brokerage" type="tel" />
                        <RHFTextField name="comexConfig.copperBrokerage" label="Copper Brokerage" type="tel" />
                        <RHFTextField name="comexConfig.NGBrokerage" label="NG Brokerage" type="tel" />
                        <RHFTextField name="comexConfig.crudeBrokerage" label="crude Brokerage" type="tel" />
                      </Box>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          )} */}
          {activeStep === 3 && (
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFSwitch
                name="clientCreationConfig.limitClientCreation"
                label="Limit Client Creation"
                labelPlacement="start"
                sx={{ justifyContent: 'start' }}
              />
              {methods.getValues().clientCreationConfig.limitClientCreation && (
                <RHFTextField
                  name="clientCreationConfig.maximumClientAllowed"
                  label="Maximum Clients Allowed"
                  type="tel"
                />
              )}
            </Box>
          )}

          <Stack sx={{ mt: 3, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {activeStep > 0 && (
              <LoadingButton variant="outlined" onClick={handlePreviousStep} sx={{ mr: 2, textTransform: 'uppercase' }}>
                Back
              </LoadingButton>
            )}
            {activeStep < STEPS.length - 1 ? (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ textTransform: 'uppercase' }}
              >
                Next
              </LoadingButton>
            ) : (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ textTransform: 'uppercase' }}
              >
                {isEdit ? 'Update' : 'Create'} {userType}
              </LoadingButton>
            )}
          </Stack>
        </Card>
      </Grid>
    </FormProvider>
  );
}
