import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Download } from '@mui/icons-material';
import { TableRow, TableCell, IconButton, Tooltip, CircularProgress } from '@mui/material';
import { fDateTime } from '../../../utils/formatTime';

UserFileListRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};

export default function UserFileListRow({ row, selected }) {
  const { s3Key, username, firstname, lastname, uploadedAt } = row;
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        '/trade/download-pdf',
        { fileKey: s3Key },
        {
          responseType: 'blob',
        }
      );

      // Create a new Blob object
      const fileBlob = new Blob([response.data], { type: 'application/pdf' });

      // Create a URL for the Blob and open it in a new tab
      const fileURL = window.URL.createObjectURL(fileBlob);
      window.open(fileURL, '_blank');
    } catch (error) {
      console.error('Failed to download the file:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="left">{username}</TableCell>
        <TableCell align="left">{firstname || lastname ? `${firstname} ${lastname}` : '-'}</TableCell>
        <TableCell align="left">{s3Key}</TableCell>
        <TableCell align="left">{fDateTime(uploadedAt)}</TableCell>
        <TableCell align="center">
          <Tooltip title="Download">
            <IconButton onClick={handleDownload} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <Download />}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
}
